:root {
    --bs-blue-100:#cfe2ff;
    --bs-blue-200:#9ec5fe;
    --bs-blue-300:#6ea8fe;
    --bs-blue-400:#3d8bfd;
    --bs-blue-500:#0d6efd;
    --bs-blue-600:#0a58ca;
    --bs-blue-700:#084298;
    --bs-blue-800:#052c65;
    --bs-blue-900:#031633;

    --bs-indigo-100:#c5cae9;
    --bs-indigo-200:#9fa8da;
    --bs-indigo-300:#7986cb;
    --bs-indigo-400:#5c6bc0;
    --bs-indigo-500:#3f51b5;
    --bs-indigo-600:#3949ab;
    --bs-indigo-700:#303f9f;
    --bs-indigo-800:#283593;
    --bs-indigo-900:#1a237e;

    --bs-purple-100:#e2d9f3;
    --bs-purple-200:#c5b3e6;
    --bs-purple-300:#a98eda;
    --bs-purple-400:#8c68cd;
    --bs-purple-500:#6f42c1;
    --bs-purple-600:#59359a;
    --bs-purple-700:#432874;
    --bs-purple-800:#2c1a4d;
    --bs-purple-900:#160d27;

    --bs-pink-100:#f7d6e6;
    --bs-pink-200:#ffb6ce;
    --bs-pink-300:#ff8bb2;
    --bs-pink-400:#ff6f9f;
    --bs-pink-500:#ff4081;
    --bs-pink-600:#c73264;
    --bs-pink-700:#93215a;
    --bs-pink-800:#561435;
    --bs-pink-900:#2b0a1a;

    --bs-red-100:#f8d7da;
    --bs-red-200:#f1aeb5;
    --bs-red-300:#ea868f;
    --bs-red-400:#e35d6a;
    --bs-red-500:#dc3545;
    --bs-red-600:#b02a37;
    --bs-red-700:#842029;
    --bs-red-800:#58151c;
    --bs-red-900:#2c0b0e;

    --bs-orange-100:#ffe5d0;
    --bs-orange-200:#fecba1;
    --bs-orange-300:#feb272;
    --bs-orange-400:#fd9843;
    --bs-orange-500:#fd7e14;
    --bs-orange-600:#ca6510;
    --bs-orange-700:#984c0c;
    --bs-orange-800:#653208;
    --bs-orange-900:#331904;

    --bs-yellow-100:#fff3cd;
    --bs-yellow-200:#ffe69c;
    --bs-yellow-300:#ffda6a;
    --bs-yellow-400:#ffcd39;
    --bs-yellow-500:#ffc107;
    --bs-yellow-600:#cc9a06;
    --bs-yellow-700:#997404;
    --bs-yellow-800:#664d03;
    --bs-yellow-900:#332701;

    --mdb-green-100:#C8E6C9;
    --mdb-green-200:#A5D6A7;
    --mdb-green-300:#81C784;
    --mdb-green-400:#66BB6A;
    --mdb-green-500:#4CAF50;
    --mdb-green-600:#43A047;
    --mdb-green-700:#388E3C;
    --mdb-green-800:#2E7D32;
    --mdb-green-900:#1B5E20;

    --mdb-teal-100:#b2dfdb;
    --mdb-teal-200:#80cbc4;
    --mdb-teal-300:#4db6ac;
    --mdb-teal-400:#26a69a;
    --mdb-teal-500:#009688;
    --mdb-teal-600:#00897b;
    --mdb-teal-700:#00796b;
    --mdb-teal-800:#00695c;
    --mdb-teal-900:#004d40;

    --bs-cyan-100:#cff4fc;
    --bs-cyan-200:#9eeaf9;
    --bs-cyan-300:#6edff6;
    --bs-cyan-400:#3dd5f3;
    --bs-cyan-500:#0dcaf0;
    --bs-cyan-600:#0aa2c0;
    --bs-cyan-700:#087990;
    --bs-cyan-800:#055160;
    --bs-cyan-900:#032830;

    --bs-gray-100:#f8f9fa;
    --bs-gray-200:#e9ecef;
    --bs-gray-300:#dee2e6;
    --bs-gray-400:#ced4da;
    --bs-gray-500:#adb5bd;
    --bs-gray-600:#6c757d;
    --bs-gray-700:#495057;
    --bs-gray-800:#343a40;
    --bs-gray-900:#212529;

    --mdb-brown-100:#D7CCC8;
    --mdb-brown-200:#BCAAA4;
    --mdb-brown-300:#A1887F;
    --mdb-brown-400:#8D6E63;
    --mdb-brown-500:#795548;
    --mdb-brown-600:#6D4C41;
    --mdb-brown-700:#5D4037;
    --mdb-brown-800:#4E342E;
    --mdb-brown-900:#3E2723;
}

/* ==========================================================================
Texts
========================================================================== */

.text-blue-100 { color: var(--bs-blue-100)!important }
.text-blue-200 { color: var(--bs-blue-200)!important }
.text-blue-300 { color: var(--bs-blue-300)!important }
.text-blue-400 { color: var(--bs-blue-400)!important }
.text-blue-500, .text-blue { color: var(--bs-blue-500)!important }
.text-blue-600 { color: var(--bs-blue-600)!important }
.text-blue-700 { color: var(--bs-blue-700)!important }
.text-blue-800 { color: var(--bs-blue-800)!important }
.text-blue-900 { color: var(--bs-blue-900)!important }

.text-indigo-100 { color: var(--bs-indigo-100)!important }
.text-indigo-200 { color: var(--bs-indigo-200)!important }
.text-indigo-300 { color: var(--bs-indigo-300)!important }
.text-indigo-400 { color: var(--bs-indigo-400)!important }
.text-indigo-500, .text-indigo { color: var(--bs-indigo-500)!important }
.text-indigo-600 { color: var(--bs-indigo-600)!important }
.text-indigo-700 { color: var(--bs-indigo-700)!important }
.text-indigo-800 { color: var(--bs-indigo-800)!important }
.text-indigo-900 { color: var(--bs-indigo-900)!important }

.text-purple-100 { color: var(--bs-purple-100)!important }
.text-purple-200 { color: var(--bs-purple-200)!important }
.text-purple-300 { color: var(--bs-purple-300)!important }
.text-purple-400 { color: var(--bs-purple-400)!important }
.text-purple-500, .text-purple { color: var(--bs-purple-500)!important }
.text-purple-600 { color: var(--bs-purple-600)!important }
.text-purple-700 { color: var(--bs-purple-700)!important }
.text-purple-800 { color: var(--bs-purple-800)!important }
.text-purple-900 { color: var(--bs-purple-900)!important }

.text-pink-100 { color: var(--bs-pink-100)!important }
.text-pink-200 { color: var(--bs-pink-200)!important }
.text-pink-300 { color: var(--bs-pink-300)!important }
.text-pink-400 { color: var(--bs-pink-400)!important }
.text-pink-500, .text-pink { color: var(--bs-pink-500)!important }
.text-pink-600 { color: var(--bs-pink-600)!important }
.text-pink-700 { color: var(--bs-pink-700)!important }
.text-pink-800 { color: var(--bs-pink-800)!important }
.text-pink-900 { color: var(--bs-pink-900)!important }

.text-red-100 { color: var(--bs-red-100)!important }
.text-red-200 { color: var(--bs-red-200)!important }
.text-red-300 { color: var(--bs-red-300)!important }
.text-red-400 { color: var(--bs-red-400)!important }
.text-red-500, .text-red { color: var(--bs-red-500)!important }
.text-red-600 { color: var(--bs-red-600)!important }
.text-red-700 { color: var(--bs-red-700)!important }
.text-red-800 { color: var(--bs-red-800)!important }
.text-red-900 { color: var(--bs-red-900)!important }

.text-orange-100 { color: var(--bs-orange-100)!important }
.text-orange-200 { color: var(--bs-orange-200)!important }
.text-orange-300 { color: var(--bs-orange-300)!important }
.text-orange-400 { color: var(--bs-orange-400)!important }
.text-orange-500, .text-orange { color: var(--bs-orange-500)!important }
.text-orange-600 { color: var(--bs-orange-600)!important }
.text-orange-700 { color: var(--bs-orange-700)!important }
.text-orange-800 { color: var(--bs-orange-800)!important }
.text-orange-900 { color: var(--bs-orange-900)!important }

.text-yellow-100 { color: var(--bs-yellow-100)!important }
.text-yellow-200 { color: var(--bs-yellow-200)!important }
.text-yellow-300 { color: var(--bs-yellow-300)!important }
.text-yellow-400 { color: var(--bs-yellow-400)!important }
.text-yellow-500, .text-yellow { color: var(--bs-yellow-500)!important }
.text-yellow-600 { color: var(--bs-yellow-600)!important }
.text-yellow-700 { color: var(--bs-yellow-700)!important }
.text-yellow-800 { color: var(--bs-yellow-800)!important }
.text-yellow-900 { color: var(--bs-yellow-900)!important }

.text-green-100 { color: var(--mdb-green-100)!important }
.text-green-200 { color: var(--mdb-green-200)!important }
.text-green-300 { color: var(--mdb-green-300)!important }
.text-green-400 { color: var(--mdb-green-400)!important }
.text-green-500, .text-green { color: var(--mdb-green-500)!important }
.text-green-600 { color: var(--mdb-green-600)!important }
.text-green-700 { color: var(--mdb-green-700)!important }
.text-green-800 { color: var(--mdb-green-800)!important }
.text-green-900 { color: var(--mdb-green-900)!important }

.text-teal-100 { color: var(--mdb-teal-100)!important }
.text-teal-200 { color: var(--mdb-teal-200)!important }
.text-teal-300 { color: var(--mdb-teal-300)!important }
.text-teal-400 { color: var(--mdb-teal-400)!important }
.text-teal-500, .text-teal { color: var(--mdb-teal-500)!important }
.text-teal-600 { color: var(--mdb-teal-600)!important }
.text-teal-700 { color: var(--mdb-teal-700)!important }
.text-teal-800 { color: var(--mdb-teal-800)!important }
.text-teal-900 { color: var(--mdb-teal-900)!important }

.text-cyan-100 { color: var(--bs-cyan-100)!important }
.text-cyan-200 { color: var(--bs-cyan-200)!important }
.text-cyan-300 { color: var(--bs-cyan-300)!important }
.text-cyan-400 { color: var(--bs-cyan-400)!important }
.text-cyan-500, .text-cyan { color: var(--bs-cyan-500)!important }
.text-cyan-600 { color: var(--bs-cyan-600)!important }
.text-cyan-700 { color: var(--bs-cyan-700)!important }
.text-cyan-800 { color: var(--bs-cyan-800)!important }
.text-cyan-900 { color: var(--bs-cyan-900)!important }

.text-gray-100 { color: var(--bs-gray-100)!important }
.text-gray-200 { color: var(--bs-gray-200)!important }
.text-gray-300 { color: var(--bs-gray-300)!important }
.text-gray-400 { color: var(--bs-gray-400)!important }
.text-gray-500, .text-gray { color: var(--bs-gray-500)!important }
.text-gray-600 { color: var(--bs-gray-600)!important }
.text-gray-700 { color: var(--bs-gray-700)!important }
.text-gray-800 { color: var(--bs-gray-800)!important }
.text-gray-900 { color: var(--bs-gray-900)!important }

.text-brown-100 { color: var(--mdb-brown-100)!important }
.text-brown-200 { color: var(--mdb-brown-200)!important }
.text-brown-300 { color: var(--mdb-brown-300)!important }
.text-brown-400 { color: var(--mdb-brown-400)!important }
.text-brown-500, .text-brown { color: var(--mdb-brown-500)!important }
.text-brown-600 { color: var(--mdb-brown-600)!important }
.text-brown-700 { color: var(--mdb-brown-700)!important }
.text-brown-800 { color: var(--mdb-brown-800)!important }
.text-brown-900 { color: var(--mdb-brown-900)!important }

/* ==========================================================================
Backgrounds
========================================================================== */

.bg-blue-100 { background-color: var(--bs-blue-100)!important }
.bg-blue-200 { background-color: var(--bs-blue-200)!important }
.bg-blue-300 { background-color: var(--bs-blue-300)!important }
.bg-blue-400 { background-color: var(--bs-blue-400)!important }
.bg-blue-500, .bg-blue { background-color: var(--bs-blue-500)!important }
.bg-blue-600 { background-color: var(--bs-blue-600)!important }
.bg-blue-700 { background-color: var(--bs-blue-700)!important }
.bg-blue-800 { background-color: var(--bs-blue-800)!important }
.bg-blue-900 { background-color: var(--bs-blue-900)!important }

.bg-indigo-100 { background-color: var(--bs-indigo-100)!important }
.bg-indigo-200 { background-color: var(--bs-indigo-200)!important }
.bg-indigo-300 { background-color: var(--bs-indigo-300)!important }
.bg-indigo-400 { background-color: var(--bs-indigo-400)!important }
.bg-indigo-500, .bg-indigo { background-color: var(--bs-indigo-500)!important }
.bg-indigo-600 { background-color: var(--bs-indigo-600)!important }
.bg-indigo-700 { background-color: var(--bs-indigo-700)!important }
.bg-indigo-800 { background-color: var(--bs-indigo-800)!important }
.bg-indigo-900 { background-color: var(--bs-indigo-900)!important }

.bg-purple-100 { background-color: var(--bs-purple-100)!important }
.bg-purple-200 { background-color: var(--bs-purple-200)!important }
.bg-purple-300 { background-color: var(--bs-purple-300)!important }
.bg-purple-400 { background-color: var(--bs-purple-400)!important }
.bg-purple-500, .bg-purple { background-color: var(--bs-purple-500)!important }
.bg-purple-600 { background-color: var(--bs-purple-600)!important }
.bg-purple-700 { background-color: var(--bs-purple-700)!important }
.bg-purple-800 { background-color: var(--bs-purple-800)!important }
.bg-purple-900 { background-color: var(--bs-purple-900)!important }

.bg-pink-100 { background-color: var(--bs-pink-100)!important }
.bg-pink-200 { background-color: var(--bs-pink-200)!important }
.bg-pink-300 { background-color: var(--bs-pink-300)!important }
.bg-pink-400 { background-color: var(--bs-pink-400)!important }
.bg-pink-500, .bg-pink { background-color: var(--bs-pink-500)!important }
.bg-pink-600 { background-color: var(--bs-pink-600)!important }
.bg-pink-700 { background-color: var(--bs-pink-700)!important }
.bg-pink-800 { background-color: var(--bs-pink-800)!important }
.bg-pink-900 { background-color: var(--bs-pink-900)!important }

.bg-red-100 { background-color: var(--bs-red-100)!important }
.bg-red-200 { background-color: var(--bs-red-200)!important }
.bg-red-300 { background-color: var(--bs-red-300)!important }
.bg-red-400 { background-color: var(--bs-red-400)!important }
.bg-red-500, .bg-red { background-color: var(--bs-red-500)!important }
.bg-red-600 { background-color: var(--bs-red-600)!important }
.bg-red-700 { background-color: var(--bs-red-700)!important }
.bg-red-800 { background-color: var(--bs-red-800)!important }
.bg-red-900 { background-color: var(--bs-red-900)!important }

.bg-orange-100 { background-color: var(--bs-orange-100)!important }
.bg-orange-200 { background-color: var(--bs-orange-200)!important }
.bg-orange-300 { background-color: var(--bs-orange-300)!important }
.bg-orange-400 { background-color: var(--bs-orange-400)!important }
.bg-orange-500, .bg-orange { background-color: var(--bs-orange-500)!important }
.bg-orange-600 { background-color: var(--bs-orange-600)!important }
.bg-orange-700 { background-color: var(--bs-orange-700)!important }
.bg-orange-800 { background-color: var(--bs-orange-800)!important }
.bg-orange-900 { background-color: var(--bs-orange-900)!important }

.bg-yellow-100 { background-color: var(--bs-yellow-100)!important }
.bg-yellow-200 { background-color: var(--bs-yellow-200)!important }
.bg-yellow-300 { background-color: var(--bs-yellow-300)!important }
.bg-yellow-400 { background-color: var(--bs-yellow-400)!important }
.bg-yellow-500, .bg-yellow { background-color: var(--bs-yellow-500)!important }
.bg-yellow-600 { background-color: var(--bs-yellow-600)!important }
.bg-yellow-700 { background-color: var(--bs-yellow-700)!important }
.bg-yellow-800 { background-color: var(--bs-yellow-800)!important }
.bg-yellow-900 { background-color: var(--bs-yellow-900)!important }

.bg-green-100 { background-color: var(--mdb-green-100)!important }
.bg-green-200 { background-color: var(--mdb-green-200)!important }
.bg-green-300 { background-color: var(--mdb-green-300)!important }
.bg-green-400 { background-color: var(--mdb-green-400)!important }
.bg-green-500, .bg-green { background-color: var(--mdb-green-500)!important }
.bg-green-600 { background-color: var(--mdb-green-600)!important }
.bg-green-700 { background-color: var(--mdb-green-700)!important }
.bg-green-800 { background-color: var(--mdb-green-800)!important }
.bg-green-900 { background-color: var(--mdb-green-900)!important }

.bg-teal-100 { background-color: var(--mdb-teal-100)!important }
.bg-teal-200 { background-color: var(--mdb-teal-200)!important }
.bg-teal-300 { background-color: var(--mdb-teal-300)!important }
.bg-teal-400 { background-color: var(--mdb-teal-400)!important }
.bg-teal-500, .bg-teal { background-color: var(--mdb-teal-500)!important }
.bg-teal-600 { background-color: var(--mdb-teal-600)!important }
.bg-teal-700 { background-color: var(--mdb-teal-700)!important }
.bg-teal-800 { background-color: var(--mdb-teal-800)!important }
.bg-teal-900 { background-color: var(--mdb-teal-900)!important }

.bg-cyan-100 { background-color: var(--bs-cyan-100)!important }
.bg-cyan-200 { background-color: var(--bs-cyan-200)!important }
.bg-cyan-300 { background-color: var(--bs-cyan-300)!important }
.bg-cyan-400 { background-color: var(--bs-cyan-400)!important }
.bg-cyan-500, .bg-cyan { background-color: var(--bs-cyan-500)!important }
.bg-cyan-600 { background-color: var(--bs-cyan-600)!important }
.bg-cyan-700 { background-color: var(--bs-cyan-700)!important }
.bg-cyan-800 { background-color: var(--bs-cyan-800)!important }
.bg-cyan-900 { background-color: var(--bs-cyan-900)!important }

.bg-gray-100 { background-color: var(--bs-gray-100)!important }
.bg-gray-200 { background-color: var(--bs-gray-200)!important }
.bg-gray-300 { background-color: var(--bs-gray-300)!important }
.bg-gray-400 { background-color: var(--bs-gray-400)!important }
.bg-gray-500, .bg-gray { background-color: var(--bs-gray-500)!important }
.bg-gray-600 { background-color: var(--bs-gray-600)!important }
.bg-gray-700 { background-color: var(--bs-gray-700)!important }
.bg-gray-800 { background-color: var(--bs-gray-800)!important }
.bg-gray-900 { background-color: var(--bs-gray-900)!important }

.bg-brown-100 { background-color: var(--mdb-brown-100)!important }
.bg-brown-200 { background-color: var(--mdb-brown-200)!important }
.bg-brown-300 { background-color: var(--mdb-brown-300)!important }
.bg-brown-400 { background-color: var(--mdb-brown-400)!important }
.bg-brown-500, .bg-brown { background-color: var(--mdb-brown-500)!important }
.bg-brown-600 { background-color: var(--mdb-brown-600)!important }
.bg-brown-700 { background-color: var(--mdb-brown-700)!important }
.bg-brown-800 { background-color: var(--mdb-brown-800)!important }
.bg-brown-900 { background-color: var(--mdb-brown-900)!important }

/* ==========================================================================
Border
========================================================================== */

.border-blue-100 { border-color: var(--bs-blue-100)!important }
.border-blue-200 { border-color: var(--bs-blue-200)!important }
.border-blue-300 { border-color: var(--bs-blue-300)!important }
.border-blue-400 { border-color: var(--bs-blue-400)!important }
.border-blue-500, .border-blue { border-color: var(--bs-blue-500)!important }
.border-blue-600 { border-color: var(--bs-blue-600)!important }
.border-blue-700 { border-color: var(--bs-blue-700)!important }
.border-blue-800 { border-color: var(--bs-blue-800)!important }
.border-blue-900 { border-color: var(--bs-blue-900)!important }

.border-indigo-100 { border-color: var(--bs-indigo-100)!important }
.border-indigo-200 { border-color: var(--bs-indigo-200)!important }
.border-indigo-300 { border-color: var(--bs-indigo-300)!important }
.border-indigo-400 { border-color: var(--bs-indigo-400)!important }
.border-indigo-500, .border-indigo { border-color: var(--bs-indigo-500)!important }
.border-indigo-600 { border-color: var(--bs-indigo-600)!important }
.border-indigo-700 { border-color: var(--bs-indigo-700)!important }
.border-indigo-800 { border-color: var(--bs-indigo-800)!important }
.border-indigo-900 { border-color: var(--bs-indigo-900)!important }

.border-purple-100 { border-color: var(--bs-purple-100)!important }
.border-purple-200 { border-color: var(--bs-purple-200)!important }
.border-purple-300 { border-color: var(--bs-purple-300)!important }
.border-purple-400 { border-color: var(--bs-purple-400)!important }
.border-purple-500, .border-purple { border-color: var(--bs-purple-500)!important }
.border-purple-600 { border-color: var(--bs-purple-600)!important }
.border-purple-700 { border-color: var(--bs-purple-700)!important }
.border-purple-800 { border-color: var(--bs-purple-800)!important }
.border-purple-900 { border-color: var(--bs-purple-900)!important }

.border-pink-100 { border-color: var(--bs-pink-100)!important }
.border-pink-200 { border-color: var(--bs-pink-200)!important }
.border-pink-300 { border-color: var(--bs-pink-300)!important }
.border-pink-400 { border-color: var(--bs-pink-400)!important }
.border-pink-500, .border-pink { border-color: var(--bs-pink-500)!important }
.border-pink-600 { border-color: var(--bs-pink-600)!important }
.border-pink-700 { border-color: var(--bs-pink-700)!important }
.border-pink-800 { border-color: var(--bs-pink-800)!important }
.border-pink-900 { border-color: var(--bs-pink-900)!important }

.border-red-100 { border-color: var(--bs-red-100)!important }
.border-red-200 { border-color: var(--bs-red-200)!important }
.border-red-300 { border-color: var(--bs-red-300)!important }
.border-red-400 { border-color: var(--bs-red-400)!important }
.border-red-500, .border-red { border-color: var(--bs-red-500)!important }
.border-red-600 { border-color: var(--bs-red-600)!important }
.border-red-700 { border-color: var(--bs-red-700)!important }
.border-red-800 { border-color: var(--bs-red-800)!important }
.border-red-900 { border-color: var(--bs-red-900)!important }

.border-orange-100 { border-color: var(--bs-orange-100)!important }
.border-orange-200 { border-color: var(--bs-orange-200)!important }
.border-orange-300 { border-color: var(--bs-orange-300)!important }
.border-orange-400 { border-color: var(--bs-orange-400)!important }
.border-orange-500, .border-orange { border-color: var(--bs-orange-500)!important }
.border-orange-600 { border-color: var(--bs-orange-600)!important }
.border-orange-700 { border-color: var(--bs-orange-700)!important }
.border-orange-800 { border-color: var(--bs-orange-800)!important }
.border-orange-900 { border-color: var(--bs-orange-900)!important }

.border-yellow-100 { border-color: var(--bs-yellow-100)!important }
.border-yellow-200 { border-color: var(--bs-yellow-200)!important }
.border-yellow-300 { border-color: var(--bs-yellow-300)!important }
.border-yellow-400 { border-color: var(--bs-yellow-400)!important }
.border-yellow-500, .border-yellow { border-color: var(--bs-yellow-500)!important }
.border-yellow-600 { border-color: var(--bs-yellow-600)!important }
.border-yellow-700 { border-color: var(--bs-yellow-700)!important }
.border-yellow-800 { border-color: var(--bs-yellow-800)!important }
.border-yellow-900 { border-color: var(--bs-yellow-900)!important }

.border-green-100 { border-color: var(--mdb-green-100)!important }
.border-green-200 { border-color: var(--mdb-green-200)!important }
.border-green-300 { border-color: var(--mdb-green-300)!important }
.border-green-400 { border-color: var(--mdb-green-400)!important }
.border-green-500, .border-green { border-color: var(--mdb-green-500)!important }
.border-green-600 { border-color: var(--mdb-green-600)!important }
.border-green-700 { border-color: var(--mdb-green-700)!important }
.border-green-800 { border-color: var(--mdb-green-800)!important }
.border-green-900 { border-color: var(--mdb-green-900)!important }

.border-teal-100 { border-color: var(--mdb-teal-100)!important }
.border-teal-200 { border-color: var(--mdb-teal-200)!important }
.border-teal-300 { border-color: var(--mdb-teal-300)!important }
.border-teal-400 { border-color: var(--mdb-teal-400)!important }
.border-teal-500, .border-teal { border-color: var(--mdb-teal-500)!important }
.border-teal-600 { border-color: var(--mdb-teal-600)!important }
.border-teal-700 { border-color: var(--mdb-teal-700)!important }
.border-teal-800 { border-color: var(--mdb-teal-800)!important }
.border-teal-900 { border-color: var(--mdb-teal-900)!important }

.border-cyan-100 { border-color: var(--bs-cyan-100)!important }
.border-cyan-200 { border-color: var(--bs-cyan-200)!important }
.border-cyan-300 { border-color: var(--bs-cyan-300)!important }
.border-cyan-400 { border-color: var(--bs-cyan-400)!important }
.border-cyan-500, .border-cyan { border-color: var(--bs-cyan-500)!important }
.border-cyan-600 { border-color: var(--bs-cyan-600)!important }
.border-cyan-700 { border-color: var(--bs-cyan-700)!important }
.border-cyan-800 { border-color: var(--bs-cyan-800)!important }
.border-cyan-900 { border-color: var(--bs-cyan-900)!important }

.border-gray-100 { border-color: var(--bs-gray-100)!important }
.border-gray-200 { border-color: var(--bs-gray-200)!important }
.border-gray-300 { border-color: var(--bs-gray-300)!important }
.border-gray-400 { border-color: var(--bs-gray-400)!important }
.border-gray-500, .border-gray { border-color: var(--bs-gray-500)!important }
.border-gray-600 { border-color: var(--bs-gray-600)!important }
.border-gray-700 { border-color: var(--bs-gray-700)!important }
.border-gray-800 { border-color: var(--bs-gray-800)!important }
.border-gray-900 { border-color: var(--bs-gray-900)!important }

.border-brown-100 { border-color: var(--mdb-brown-100)!important }
.border-brown-200 { border-color: var(--mdb-brown-200)!important }
.border-brown-300 { border-color: var(--mdb-brown-300)!important }
.border-brown-400 { border-color: var(--mdb-brown-400)!important }
.border-brown-500, .border-brown { border-color: var(--mdb-brown-500)!important }
.border-brown-600 { border-color: var(--mdb-brown-600)!important }
.border-brown-700 { border-color: var(--mdb-brown-700)!important }
.border-brown-800 { border-color: var(--mdb-brown-800)!important }
.border-brown-900 { border-color: var(--mdb-brown-900)!important }
