/* ==========================================================================
Configs
========================================================================== */

@import "~@ng-select/ng-select/themes/material.theme.css";
@import 'assets/css/bs-colors.css';

@font-face { font-family: 'Material Symbols Outlined'; font-style: normal; font-weight: 500; src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v134/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCdHOejbd5zrDAt.woff2) format('woff2'); }
.material-symbols-outlined { font-family: 'Material Symbols Outlined'; font-weight: normal; font-style: normal; font-size: 24px; line-height: 1; letter-spacing: normal; text-transform: none; display: inline-block; white-space: nowrap; word-wrap: normal; direction: ltr; -webkit-font-feature-settings: 'liga'; -webkit-font-smoothing: antialiased; }

/* ==========================================================================
Material Icons
========================================================================== */

:root {
    --bg: #F4F6FF
}

html, body, main { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: var(--bg); }

a { text-decoration: none; }
a:hover { text-decoration: underline; }

/* autocomplete */
input:-webkit-autofill { -webkit-text-fill-color: initial !important; -webkit-box-shadow: 0 0 0 30px whitesmoke inset !important; }
input:hover:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #ededed inset !important; }
input:focus:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #dedede inset !important; }

/* ==========================================================================
Material
========================================================================== */

.mdc-button.btn-lg { height: 56px; font-size: 1rem; }
.mdc-button.btn-sm { height: 23px; font-size: .75rem; }
.mat-mdc-snack-bar-action.mdc-button { padding: 0; min-width: 2rem; }

.mat-mdc-unelevated-button.mat-primary { --mdc-filled-button-container-color: var(--bs-primary);  }
.mat-mdc-mini-fab.mat-primary { --mdc-fab-container-color: var(--bs-primary) }
.mat-mdc-card { --mdc-elevated-card-container-elevation: 0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A }
.mat-expansion-panel:not([class*=mat-elevation-z]) { box-shadow: 0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A !important }

.mat-mdc-table .mdc-data-table__header-row { height: 35px; background-color: var(--bs-gray-100); }
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix { padding-top: 4px; padding-bottom: 4px;}
.mat-mdc-paginator .mat-mdc-form-field-infix { min-height: 25px; }
.mat-mdc-paginator-page-size-select { width: 70px!important; }

.mat-mdc-tab-header { background-color: rgba(0,0,0,0.03); }

.input-sm { position: relative; margin-top: .4rem }
.input-sm .mat-mdc-form-field-infix { min-height: 36px; }
.input-sm .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix { padding-top: 6px; padding-bottom: 0; }
.input-sm .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label { top: 17px; }
.input-sm .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above{--mat-mdc-form-field-label-transform: translateY( -24.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));transform:var(--mat-mdc-form-field-label-transform)}
.input-sm .mat-mdc-form-field-subscript-wrapper { position: absolute; bottom: -22px; }
.input-sm .mat-mdc-icon-button.mat-mdc-button-base { --mdc-icon-button-state-layer-size: 36px; padding: 0; }

.btn-spinner { width: 25px!important; height: 25px!important; --mdc-circular-progress-size: 25px!important; --mdc-circular-progress-active-indicator-width: 25px!important; }
.btn-dialog-spinner { padding-left: 27px!important; padding-right: 27px!important; }

.mdc-list { padding: 0!important; }
.mdc-list-item, .mdc-list-item.mdc-list-item--with-one-line { height: auto!important; }
.list-border .mdc-list-item { border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important; }
.list-border .mdc-list-item:not(:last-child) { border-bottom: none!important; }
.list-border .mdc-list-item:first-child { border-top-left-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px)); border-top-right-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px)); }
.list-border .mdc-list-item:last-child { border-bottom-left-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px)); border-bottom-right-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px)); }

.disabled { cursor: not-allowed; }
.disabled .mdc-text-field { background-color: #ccc; }
.disabled .mdc-text-field .mat-mdc-input-element, .disabled .mdc-text-field .mdc-floating-label { color: #757575; cursor: not-allowed; }
.disabled .mdc-line-ripple::before { border-bottom-color: rgba(0, 0, 0, 0.4); }

.primary.mat-mdc-snack-bar-container, .success.mat-mdc-snack-bar-container, .danger.mat-mdc-snack-bar-container { --mat-snack-bar-button-color: rgba(255, 255, 255, 0.87); }
.primary.mat-mdc-snack-bar-container { --mdc-snackbar-container-color: var(--bs-blue-400); }
.success.mat-mdc-snack-bar-container { --mdc-snackbar-container-color: var(--bs-green); }
.warning.mat-mdc-snack-bar-container { --mdc-snackbar-container-color: var(--bs-warning); --mat-snack-bar-button-color: #333; --mdc-snackbar-supporting-text-color: #333 }
.danger.mat-mdc-snack-bar-container { --mdc-snackbar-container-color: var(--bs-danger); }

.mat-button { position: relative; display: inline-flex; align-items: center; justify-content: center; box-sizing: border-box; min-width: 64px; border: none; outline: none; line-height: inherit; user-select: none; appearance: none; overflow: visible; vertical-align: middle; background: rgba(0,0,0,0); padding: 0 8px 0 8px; height: var(--mdc-text-button-container-height, 36px); border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px)); -webkit-tap-highlight-color: rgba(0,0,0,0); -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)); font-size: var(--mdc-typography-button-font-size, 14px); font-weight: var(--mdc-typography-button-font-weight, 500); letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em); text-decoration: var(--mdc-typography-button-text-decoration, none); text-transform: var(--mdc-typography-button-text-transform, none);}
.mat-button.mat-primary:hover { background-color: rgba(63, 81, 181, 0.05); }
.mat-button.mat-primary { color: #3f51b5 }
.mat-button.mat-warn:hover { background-color:rgba(244, 67, 54, 0.05); }
.mat-button.mat-warn { color: #f44336; }
.mat-button.mat-accent:hover { background-color:rgba(255, 64, 129, 0.05); }
.mat-button.mat-accent { color: #ff4081; }

.icon-lg { height: 30px!important; width: 30px!important; font-size: 30px!important; }
.icon-sm { height: 18px!important; width: 18px!important; font-size: 18px!important; }

.mat-mdc-icon-button { display: flex; }
.mat-mdc-icon-button.btn-sm { --mdc-icon-button-state-layer-size: 36px; width: var(--mdc-icon-button-state-layer-size); height: var(--mdc-icon-button-state-layer-size); padding: 4px; }
.mat-mdc-icon-button.active { background-color: var(--bs-primary); color: white; }

.mat-expansion-panel-header, .mat-expansion-panel-header.mat-expanded { padding: 0 1rem!important; }
.mat-expansion-panel-body { padding: 0 1rem 16px!important; }

.mat-mdc-dialog-surface { overflow: hidden!important; }
.mat-mdc-dialog-content { max-height: 75vh!important; }
.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after { color: red; }

.p-0 .mat-mdc-form-field-subscript-wrapper { margin-bottom: -1.25em!important; }

.table-sm { height: 35px!important; }

/* ==========================================================================
NgSelect Material
========================================================================== */

.ng-select .ng-select-container { min-height: 57px; background-color: whitesmoke; border-top-left-radius: 4px; border-top-right-radius: 4px; }
.ng-select .ng-select-container:hover { background-color: #ededed; }
.ng-select .ng-select-container .ng-value-container { padding: 0.4375em 1rem; border-top: 1.1em solid transparent; }
.ng-select .ng-select-container .ng-value-container .ng-placeholder { top: 1.1rem; }

.ng-select .ng-has-value .ng-placeholder, .ng-select.ng-select-opened .ng-placeholder, 
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder { transform: translateY(-0.6em) scale(0.75) perspective(100px) translateZ(0.001px) }
.ng-select:not(.ng-select-multiple) .ng-select-container .ng-value-container .ng-input>input { width: calc(100% - 2rem)!important; margin: 0 1rem; }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input { margin-top: 0.2rem; }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value { margin: 0 .2rem 0 0; }
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value { margin-top: 1px; }

.ng-select.ng-select-disabled .ng-select-container:after { border-bottom-color: #EDEDED; background-image: initial; background-size: initial; background-repeat: initial; }
.ng-select.ng-select-disabled .ng-select-container { background-color: #FAFAFA; }

.ng-select .ng-spinner-loader { margin-right: 10px!important; margin-top: 27px!important; }
.ng-select .ng-arrow-wrapper {  margin-right: 10px; }

.ng-select[required] .ng-placeholder::after { content:'*'; margin-left: 2px; color: red; }

/* ==========================================================================
Custom
========================================================================== */

hr { border-color: var(--bs-gray); }

table { width: 100%; table-layout: auto; white-space: normal; overflow: hidden; }
table thead tr { background-color: rgba(var(--bs-light-rgb), 1) !important; border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;}
table thead tr th:not(.mdc-data-table__header-cell) { font-size: var(--mdc-typography-subtitle2-font-size, 14px)!important; font-weight: var(--mdc-typography-subtitle2-font-weight, 500)!important; color: black!important; padding: .25rem 16px .25rem 16px !important;}
table tr td { font-size: var(--mdc-typography-body2-font-size, 14px)!important; font-weight: var(--mdc-typography-body2-font-weight, 400)!important; color: black!important; padding: 0 16px 0 16px;}

.pointer { cursor: pointer; }

.action { cursor: pointer; }
.action:hover { border-color: #86b7fe!important; box-shadow: 0 0 0 0.25rem rgba(13,110,253,.1)!important; }

.bg-waves { background-image: url('assets/img/bg.png'); min-height: 100%; }

.callout { border-left-color: var(--bs-gray-400); border-left-width: 5px !important; border-left-style: solid !important; border-radius: 0.25rem; background-color: var(--bs-gray-100); padding: .75rem 1rem; }

.dev { position: fixed; top: 2rem; right: -3rem; transform: rotate(45deg); background-color: rgba(255, 0, 0, .5); padding: .5rem 3.3rem; z-index: 9999; }
.dev::after { content: 'EM CONSTRUÇÃO'; color: white; font-size: .8rem; }

.fs-7 { font-size: .75rem!important; line-height: 1.25; }

.td-w-auto { white-space: nowrap; width: 1%; padding: 0!important; }

.img-cover { object-fit: cover; }
.img-invert { filter: invert(); }
.bg-default { background-image: url('assets/img/default_img.jpeg'); background-size: 100%; }

.divAvatar { min-height: 140px; min-width: 140px; }

.status-badge { font-size: .65rem; display: inline-block; border-radius: var(--bs-border-radius); font-weight: 700; padding: 0rem 0.35rem; background-color: var(--bs-gray-200); text-transform: uppercase; vertical-align: middle; }

.fst-signature { font-family: "Stalemate", cursive; }

.chat { display: flex; flex-direction: column; width: calc(100% - 1rem); }
.chat .chat-body { position: relative; border-radius: 3px; border-bottom-right-radius: 0!important; padding: .75rem; }
.chat .chat-body:after { content: ''; position: absolute; right: -0.6rem; bottom: 0; border-top: 0.8rem solid transparent; border-left: 0.7rem solid var(--bs-gray-200); }

.dotted-line > div { position: relative; }
.dotted-line > div::before { content: ''; width: 100%; position: absolute; bottom: 2px; border-bottom: 1px dotted var(--bs-gray-300); z-index: 0; }
.dotted-line > div > span { background-color: white; z-index: 1; }
.dotted-line > div > span:first-of-type { padding-right: 3px; }
.dotted-line > div > span:last-of-type { padding-left: 3px; }

.gm-style-iw-chr { position: absolute; display: flex; top: 0; right: 0; }
.gm-style-iw.gm-style-iw-c { padding-top: 14px !important; padding-left: 14px !important; }
.gm-style-iw-chr button { width: 25px !important; height: 25px !important; }
.gm-style-iw-chr button span { width: 15px!important; height: 15px!important; margin: 5px!important; }

@media only screen and (max-width: 768px) {
  .bg-waves { background-size: cover; }
}

@media only screen and (max-width: 1680px) {
  .dio-table .mat-mdc-table { table-layout: fixed !important; }
}

@media print {
  .page-break { page-break-after: always; }
  .p-print-0 { padding: 0 !important; }
  #main-body { overflow: initial!important; }
  .mat-mdc-card { --mdc-elevated-card-container-elevation: 0px 0px 0px 0px #000; padding: 1rem!important; }
  .callout { border: 1px solid #000; }
}